import React, { useEffect } from "react";
import Page from "../components/Page";
import "../css/LandingPages.css";
import Button from "../components/Button";
import RichText from "../components/RichText";
import { v4 as uuid } from "uuid";
import HeroCTA from "../components/landing-page-1/HeroCTA";
import NavSwitch from "../components/NavSwitch";
import Reviews from "../components/landing-page-1/Reviews";
import ProudPartners from "../components/landing-page-1/ProudPartners";

import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import ProductCards from "../components/ProductCards";

// import NavSwitch from "../components/NavSwitch";
// import ProductRow from "../components/ProductRow";
// import Maps from "../components/Maps";
// import Table from "../components/Table";

function Landing1Page() {
  // access our data so that we can display it
  // const singleTypeSlug = "landing-page";
  // const { data } = useSelector((state) => state.cms);
  // const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  // if (!cmsData) return <LoadingPage />;
  // access our data so that we can display it
  const singleTypeSlug = "marketing-page-1";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  const suppliers = getSuppliers(cmsData);
  return (
    <Page data-wf-page="63a4ab5fc97e6a1beb2033c6" data-wf-site="63a4ab5fc97e6aeff12033c5" hidePopup={true}>
      <HeroCTA />
      <NavSwitch />
      <Reviews />
      <ProudPartners suppliers={suppliers} />
      <ProductCards products={cmsData?.products} />
    </Page>
  );
}

export default Landing1Page;

const getSuppliers = (cmsData) => {
  let suppliers = [];
  cmsData?.products?.forEach((el) => {
    console.log(el.product);
    let items = el?.product?.data?.attributes?.suppliers || [];
    suppliers = [...suppliers, ...items];
  });

  // remove items with duplicate links
  suppliers = suppliers.filter((item, index, self) => self.findIndex((t) => t.link === item.link) === index);

  console.log(suppliers);
  return suppliers;
};

// --white: white;
// --off-white: #eee;
// --orange: #f26a24;
// --dark-grey: #99a1aa;
// --light-grey: #bcbfc1;
// --blue: #22223b;
// --off-black: #333;
// --black: black;
