import React, { useState, useEffect } from "react";
import { PopupButton } from "react-calendly";

const calendlyButtons = [
  {
    label: "Kitchen Cabinets & Vanities",
    url: "https://calendly.com/michelle-niagaracountertops/kitchen-cabinets-vanities",
  },
  {
    label: "Countertops",
    url: "https://calendly.com/karen-niagaracountertops/countertops",
  },
  {
    label: "Whole Kitchens",
    url: "https://calendly.com/michelle-niagaracountertops/whole-kitchens",
  },
  {
    label: "Other",
    url: "https://calendly.com/karen-niagaracountertops/other",
  },
];
const buttonStyle = {
  color: "var(--white)",
  backgroundColor: "var(--orange)",
  padding: "1rem 2rem",
  borderRadius: "10px",
  fontSize: "1.4rem",
  boxShadow: "0px 10px 10px rgba(0,0,0,0.2)",
};

const CalendlyButtons = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <button style={buttonStyle} onClick={() => setModalOpen(true)}>
        Get a Free Quote
      </button>
      {modalOpen && <Modal onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default CalendlyButtons;

const Modal = ({ onClose }) => {
  useEffect(() => {
    const handleEscapePress = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapePress);

    return () => {
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [onClose]);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      onClick={handleBackgroundClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textShadow: "none",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "var(--white)",
          padding: "2rem",
          borderRadius: "10px",
          position: "relative",
          maxWidth: "500px",
          width: "90%",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            background: "none",
            border: "none",
            cursor: "pointer",
            background: "black",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
          }}
          onClick={onClose}
        >
          <img src="/images/x.png" alt="logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </button>
        <h2 style={{ color: "var(--black)" }}>What type of service are you interested in?</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {calendlyButtons.map((button) => (
            <PopupButton styles={buttonStyle} url={button.url} rootElement={document.getElementById("root")} text={button.label} />
          ))}
        </div>
      </div>
    </div>
  );
};
