import React from "react";
import Page from "../components/Page";
import "../css/LandingPages.css";
import HeroCTA from "../components/landing-page-2/HeroCTA";
import NavSwitch from "../components/NavSwitch";

import { useSelector } from "react-redux";
import LoadingPage from "./LoadingPage";
import ProductCards from "../components/ProductCards";
import Steps from "../components/landing-page-2/Steps";
import Reviews from "../components/landing-page-1/Reviews";

function Landing2Page() {
  const singleTypeSlug = "marketing-page-2";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;

  return (
    <Page
      data-wf-page="63a4ab5fc97e6a1beb2033c6"
      data-wf-site="63a4ab5fc97e6aeff12033c5"
      hidePopup={true}
      // Banner={() => <div style={{ backgroundColor: "var(--orange)", color: "var(--white)" }}>Banner</div>}
    >
      <HeroCTA />
      <NavSwitch />
      <Reviews />
      <Steps />

      {/* <Reviews />
      <ProudPartners /> */}
      <ProductCards products={cmsData?.products} />
    </Page>
  );
}

export default Landing2Page;

// --white: white;
// --off-white: #eee;
// --orange: #f26a24;
// --dark-grey: #99a1aa;
// --light-grey: #bcbfc1;
// --blue: #22223b;
// --off-black: #333;
// --black: black;
