import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

const ProductCards = ({ products }) => {
  return (
    <div className="section-5 wf-section">
      <div className=" product-cards">
        {products?.map((el, i) => {
          let item = el?.product?.data?.attributes;
          console.log(el);
          return (
            <Link to={el.customLink || `/products/${item.slug}`} key={uuid()} className="product-card">
              <img key={uuid()} src={item?.images?.data[0].attributes.url} />
              <div className="cardContent">
                <h2 className="heading2-subtle orange">{item.title}</h2>
                <p>{item.shortDescription || item.description}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ProductCards;
